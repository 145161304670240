<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="space-around" no-gutters>
      <v-col cols="3" v-if="!mobile">
        <v-img
            :src="require('@/assets/main/logoVitae.png')"
            contain
            height="140"
        />
      </v-col>
      <v-col cols="2" v-if="!mobile">
        <div class="textSection" style="padding-left: 75px;">
          <h3 class="titleSection">{{ $t('firstSection') }}</h3>
          <br>
          - {{ $t('firstSectionLine1') }} <br>
          - {{ $t('firstSectionLine2') }} <br>
          - {{ $t('firstSectionLine3') }}
        </div>
      </v-col>
      <v-col cols="2">
        <v-img
            :src="require('@/assets/picto/separator1.svg')"
            contain
            height="350"
        />
      </v-col>
      <v-col v-bind:cols="mobile ? 9 : 2">
        <div v-if="!mobile" style="height: 200px;"></div>
        <div v-if="mobile" class="textSection">
          <h3 class="titleSection">{{ $t('firstSection') }}</h3>
          <br>
          - {{ $t('firstSectionLine1') }} <br>
          - {{ $t('firstSectionLine2') }} <br>
          - {{ $t('firstSectionLine3') }}
        </div>
        <div v-if="mobile">
          <br><br><br>
        </div>
        <div class="textSection" style="padding-right: 75px;">
          <h3 class="titleSection">{{ $t('secondSection') }}</h3>
          <br>
          - {{ $t('secondSectionLine1') }} <br>
          - {{ $t('secondSectionLine2') }} <br>
          - {{ $t('secondSectionLine3') }}
        </div>

      </v-col>
      <v-col cols="3" v-if="!mobile">
        <v-img
            :src="require('@/assets/main/homeHealthLogo.png')"
            contain
            height="170"
        />
      </v-col>
    </v-row>
    <v-row justify="center" class="secondSection" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleDarkMobile' : 'mainTitleDark'">{{ $t('secondTitle') }}</h1>
      <v-col cols="12" class="organigrammeCol">
        <v-img
            :src="require('@/assets/vitaeImg/organigramme.png')"
            contain
            v-bind:height="mobile ? 350 : 650"
            v-if="$i18n.locale === 'fr'"
        />
        <v-img
            :src="require('@/assets/vitaeImg/organigramme-anglais.png')"
            contain
            v-bind:height="mobile ? 350 : 650"
            v-else
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['mobile'],
  name: 'OrganisationGouvernanceComp',
  data: () => ({}),
}
</script>

<i18n>
{
  "en": {
    "mainTitle": "Organization and governance",
    "secondTitle": "Organizational chart",
    "firstSection": "Health promotion pole",
    "firstSectionLine1": "Sport health well-being",
    "firstSectionLine2": "Business sport",
    "firstSectionLine3": "Sport insertion",
    "secondSection": "Health prevention center",
    "secondSectionLine1": "Sport therapy",
    "secondSectionLine2": "Senior sport",
    "secondSectionLine3": "Adapted sport"
  },
  "fr": {
    "mainTitle": "Organisation et gouvernance",
    "secondTitle": "Organigramme",
    "firstSection": "Pôle promotion santé",
    "firstSectionLine1": "Sport santé bien-être",
    "firstSectionLine2": "Sport entreprise",
    "firstSectionLine3": "Sport insertion",
    "secondSection": "Pôle prévention santé",
    "secondSectionLine1": "Sport thérapie",
    "secondSectionLine2": "Sport sénior",
    "secondSectionLine3": "Sport adapté"
  }
}
</i18n>

<style scoped>

.titleSection {
  color: #57939E;
}

.textSection {
  color: #808080;
}

.secondSection {
  margin-top: 50px;
  background-color: #1579A7;
  padding-bottom: 50px;
}

.organigrammeCol {
  padding-bottom: 100px;
}

</style>
